import React from "react";

const SelectableIcon = () => {
  return (
    <svg
      width="10"
      height="15.72"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3" r="3" fill="#E1E1E7" />
      <circle cx="3" cy="11" r="3" fill="#E1E1E7" />
      <circle cx="3" cy="19" r="3" fill="#E1E1E7" />
      <circle cx="11" cy="3" r="3" fill="#E1E1E7" />
      <circle cx="11" cy="11" r="3" fill="#E1E1E7" />
      <circle cx="11" cy="19" r="3" fill="#E1E1E7" />
    </svg>
  );
};

export default SelectableIcon;
