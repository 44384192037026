import React, { Fragment, useMemo, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import ColumnSelectorDialog, { TableTypes } from "./ColumnSelectorDialog";
import useSelectedColumns from "@lib/hooks/use-selected-columns";
import { Button } from "@components/ui/button";

type Props = {
  disabled?: boolean;
  error?: string;
  width?: string;
  type?: "button" | "select";
  table: TableTypes;
  label?: string | React.ReactNode;
  dontShowColumn?: string;
};

export type ColumnSelectorProps = Props;

export const ColumnSelector: React.FC<ColumnSelectorProps> = ({
  disabled,
  error = "",
  type = "button",
  width,
  table,
  label,
  dontShowColumn = "",
}) => {
  const [open, setOpen] = useState(false);
  const { selectedColumnsOrder, selectedColumns, setSelectedColumns } =
    useSelectedColumns({
      table,
    });
  const availableSelectedColumn = useMemo(
    () => selectedColumnsOrder,
    [selectedColumnsOrder]
  );
  if (type === "button") {
    return (
      <>
        <ColumnSelectorDialog
          table={table}
          open={open}
          onClose={() => setOpen(false)}
          dontShowColumn={dontShowColumn}
        />
        <button
          onClick={() => setOpen(true)}
          className={`relative w-full h-12 px-6 text-left transition-color rounded-md duration-200 bg-gray-100 dark:bg-gray-800 border ${
            !!error
              ? "border-red-500"
              : "border-gray-300 dark:border-gray-800 hover:border-primary"
          } cursor-pointer focus:outline-none focus-visible:outline-none focus:ring-0 focus:border-primary focus:ring-offset-0 focus-visible:ring-1 focus-visible:ring-opacity-100 focus-visible:ring-primary  focus-visible:ring-offset-primary focus-visible:ring-offset-1 focus-visible:border-primary sm:text-sm`}
        >
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-primary"
              aria-hidden="true"
            />
          </span>
          <span className="block truncate font-semibold mr-2">
            {label ? label : `Columns`} (
            {
              availableSelectedColumn.filter(
                (el) => el.value !== dontShowColumn
              )?.length
            }
            )
          </span>
        </button>
      </>
    );
  }

  return (
    <>
      <ColumnSelectorDialog
        table={table}
        open={open}
        onClose={() => setOpen(false)}
        dontShowColumn={dontShowColumn}
      />
      <Listbox
        value={selectedColumns}
        onChange={(value) => {
          setSelectedColumns((current) => {
            const map = new Map([...current]);
            map.set(table, value?.map((el) => el) ?? []);
            return map;
          });
        }}
        multiple
        disabled={disabled}
      >
        <div
          className={`relative text-foreground h-12 z-30 ${width ? width : ""}`}
        >
          <Listbox.Button
            className={`relative w-full h-full px-6 text-left transition-color rounded-md duration-200 bg-gray-100 dark:bg-gray-800 border ${
              !!error
                ? "border-red-500"
                : "border-gray-300 dark:border-gray-800 hover:border-primary"
            } cursor-pointer focus:outline-none focus-visible:outline-none focus:ring-0 focus:border-primary focus:ring-offset-0 focus-visible:ring-1 focus-visible:ring-opacity-100 focus-visible:ring-primary  focus-visible:ring-offset-primary focus-visible:ring-offset-1 focus-visible:border-primary sm:text-sm`}
          >
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-primary"
                aria-hidden="true"
              />
            </span>
            <span className="block truncate font-semibold mr-2">
              {label ? label : `Columns`} (
              {
                availableSelectedColumn?.filter(
                  (el) => el.value !== dontShowColumn
                )?.length
              }
              )
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute scrollbar w-full pt-1 mt-1 rounded-md overflow-auto text-base bg-gray-200 dark:bg-gray-800 text-foreground shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 cursor-pointer focus:outline-none sm:text-sm">
              <div className="flex font-semibold px-4 pt-2 pb-4 cursor-default">
                <p>Columns</p>
              </div>
              <div className="sticky bottom-0 inset-x-0 w-full px-2 py-2 bg-gray-200 dark:bg-gray-800">
                <Button
                  variant="primary"
                  size="sm"
                  className="w-full"
                  onClick={() => setOpen(true)}
                >
                  Modify Columns
                </Button>
              </div>
            </Listbox.Options>
          </Transition>
        </div>
        {!!error && (
          <p className="text-red-500 mt-1 text-sm" role="alert">
            {error}
          </p>
        )}
      </Listbox>
    </>
  );
};
export default ColumnSelector;
