import { BaseScore } from "@api/types/backendTypes";
import { COLUMN_OPTIONS } from "constants/constants";
type MinMaxValuesType = {
  // eslint-disable-next-line no-unused-vars
  [key in (typeof COLUMN_OPTIONS)[number]["value"]]: number;
};

export const getMinMaxValueForColumns = (data?: BaseScore[] | any[]) => {
  const minValues: MinMaxValuesType = {} as MinMaxValuesType;
  const maxValues: MinMaxValuesType = {} as MinMaxValuesType;

  for (const columnOption of COLUMN_OPTIONS) {
    minValues[columnOption.value] = 0;
    maxValues[columnOption.value] = 0;
  }

  data?.forEach((value: (typeof data)[number]) => {
    // @ts-ignore
    Object.keys(value).forEach((key: keyof MinMaxValuesType) => {
      const scoreValue = value[key] ?? 0;
      if (minValues[key] !== undefined && minValues[key] > scoreValue) {
        minValues[key] = scoreValue;
      } else if (
        maxValues[key] !== undefined &&
        value[key] !== undefined &&
        maxValues[key] < scoreValue
      ) {
        maxValues[key] = scoreValue;
      }
    });
  });
  return {
    min: minValues,
    max: maxValues,
  };
};

export const convertSelectedColumns = (
  selectedColumns: string[],
  fixedColumns?: string[]
) => {
  const obj: Record<string, boolean> = {};
  selectedColumns.map((el) => {
    obj[el] = true;
  });
  fixedColumns?.map((el) => {
    obj[el] = true;
  });
  Object.values(COLUMN_OPTIONS).map((el) => {
    if (obj[el.value] === undefined) {
      obj[el.value] = false;
    }
  });
  return obj;
};
